.header-drawer {
  display: none;
}

.ant-drawer-content-wrapper {
  width: 220px !important;
  box-shadow: none !important;
}

@media (max-width: 1024px) {
  .nav-button {
    display: none;
  }

  .header-drawer {
    display: block;
  }

  .header-breadcrumd {
    display: none;
  }
}

@media (max-width: 840px) {
  .header-drawer {
    display: block;
  }

  .nav-button {
    display: none;
  }

  .header-breadcrumd {
    display: none;
  }
}

@media (max-width: 390px) {
  .header-drawer {
    display: block;
  }

  .nav-button {
    display: none;
  }

  .header-breadcrumd {
    display: none;
  }
}
